@import '_globals.less';

.cc-bottom {
  	bottom: 0;
}

.cc-right {
  	right: 0;
}

.cc-left {
  	left: 0;
}

.cc-top {
  	top: 0;
}

.cc-window {
	.transform(translate(-50%, -50%));

	backface-visibility: hidden;
	box-shadow: 0 0 60px 20px rgba(0, 0, 0, 0.15);
	display: block;
	font-size: 14px;
	left: 50%;
	line-height: inherit;
	overflow: auto;
	position: fixed;
	top: 50%;
	z-index: 99999;

	&.cc-bottom {
		bottom: auto;
	}

	&.cc-floating {
		max-width: 820px;
		width: 100%;

		.cc-compliance {
			flex: 0;
			justify-content: space-between;
			padding: 0 20px 20px;

			.cc-btn {
				.mx-button();

				flex: 0 1 auto;
				width: 37%;

			}
		}
	}

	&.cc-color-override-1828008485 {
		background-color: @c-white !important;

		&.cc-window {
			color: @c-black;
		}
	}
}

body {
	&.dp--cookie-consent {
		overflow: hidden;
		padding-top: 0;
		position: relative;

		&::before {
			display: block;
		}
	}

	&::before {
		background: fadeOut(@c-black, 20%);
		content: '';
		display: none;
		height: 100%;
		opacity: 1;
		position: fixed;
		width: 100%;
		z-index: 99998;
	}
}

// Checkboxes
.dp--cookie-check {
	border-color: @c-primary-1;
	border-radius: 0;
	margin: 20px 0 0;
	padding: 5px 7px;
}

.cc-checkboxes {
	.mx-flex(row, nowrap);

	label {
		+ label {
			margin-left: 20px;
		}
	}
}

.cc-message {
	flex: 1;
}

.cc-message-inner {
	span {
		color: @c-black;
	}
}

.cc-headline {
	background-color: #f3f9ff;
	margin: -20px -20px 10px -20px;
	padding: 20px 20px 20px 100px;
	position: relative;

	&:before {
		background: url('../Image/vhw_logo_blue_without_text.svg') no-repeat center;
		bottom: 10px;
		content: '';
		display: block;
		left: 20px;
		position: absolute;
		top: 10px;
		width: 67px;
	}

	h2 {
		color: @c-primary-1;
		font-size: 24px;
		font-weight: 500;
		line-height: 28px;
		margin: 0;
		text-transform: none;
	}
}

.cc-floating {
	&.cc-theme-edgeless {
		max-height: 80vh;

		.cc-message {
			padding: 20px;
		}
	}
}

// Buttons
.cc-color-override-1828008485 {
	.cc-highlight {
		.cc-btn {
			transition: background-color .4s;

			&.cc-allow {
				&:first-child {
					.mx-button-blue();
				}
			}

			&.cc-dismiss {
				.mx-button-blue();
			}
		}
	}
}

.dp--cookie-consent .dp--cookie-check .cc-button-info.button {
	.mx-button-gray();
}

// Cookie Information / Tabs
.cc-button-info {
	&.js-active {
		&::before {
			.transform(rotate(180deg));
		}
	}
}

.cc-information {
	display: none;

	&.js-active {
		display: block;
	}
}

.cc-tabs-first-level,
.cc-tabs-second-level {
	ul {
		.mx-flex();

		list-style: none;
	}

	li {
		background-color: #f7f7f7;

		&.js-active {
			background-color: @c-white;
		}
	}
}

.cc-tabs-first-level {
	margin-top: 15px;

	li {
		border: 1px solid @c-primary-1;
		border-bottom: none;
		box-sizing: border-box;
		overflow: auto;

		a {
			display: block;
			padding: 10px 20px;
		}

		+ li {
			border-left: none;
		}

		&.js-active {
			overflow: visible;
			position: relative;

			&::before {
				background: @c-white;
				bottom: -1px;
				content: '';
				height: 1px;
				left: 0;
				position: absolute;
				width: 100%;
			}
		}
	}
}

.cc-tabs-second-level {
	margin: -10px 15px 0 -10px;

	ul {
		border-right: 1px solid @c-primary-1;
		display: block;
		height: 100%;
		width: 152px;
	}

	li {
		border: 1px solid @c-primary-1;
		border-left: none;
		box-sizing: border-box;
		margin: 0 -1px 0 0;
		overflow: auto;

		a {
			display: block;
			padding: 5px 10px;
			white-space: nowrap;
		}

		&.js-active {
			border-right: none;
		}

		+ li {
			border-top: none;
		}

		&:first-child {
			border-top: none;
		}
	}
}

.cc-information-contents {
	border: 1px solid @c-primary-1;
	max-height: 200px;
	overflow: auto;
	padding: 10px;

	> div {
		display: none;

		&.js-active {
			.mx-flex(row, nowrap);
		}
	}
}

.cc-inner-contents {
	> div {
		display: none;

		&.js-active {
			display: block;
		}
	}
}

.cc-inner-text,
.cc-inner-table {
	width: 100%;
}

.cc-inner-table {
	margin-bottom: 20px;
	margin-top: 20px;

	thead {
		background-color: @c-primary-1;
		color: @c-white;
	}

	th,
	td {
		padding: 5px;
		word-wrap: break-word;

		&:nth-child(4) {
			width: 60px;
		}

		&:nth-child(5) {
			width: 45px;
		}
	}

	th {
		text-align: left;
	}

	td {
		vertical-align: top;
	}

	tbody {
		tr {
			&:nth-child(even) {
				td {
					background-color: #f7f7f7;
				}
			}
		}
	}
}

.cc-inner-text {
	p {
		margin-bottom: 10px;
	}

	&:not(:only-child) {
		p {
			&:last-child {
				margin-bottom: 0;
			}
		}
	}
}


// ***********************************************************************
// MEDIA QUERY: tablet landscape
// ***********************************************************************
@media screen and (max-width: 1024px) {
	.cc-window {
		&.cc-floating {
			max-width: 90vw;
		}

		&.cc-right {
			left: 50%;
			right: auto;
		}
	}
}


// ***********************************************************************
// MEDIA QUERY: smartphone landscape
// ***********************************************************************
@media screen and (max-width: 767px) {
	.cc-window {
		&.cc-floating {
			.cc-compliance {
				display: block;

				.cc-btn {
					width: 100%;

					+ .cc-btn {
						margin-top: 10px;
					}
				}
			}
		}
	}

	.cc-information-contents {
		> div {
			&.js-active {
				display: block;
			}
		}
	}

	.cc-tabs-second-level {
		margin: 0;

		ul {
			border-bottom: 1px solid @c-primary-1;
			border-right: 0;
			display: flex;
			margin: 0 0 10px;
			flex-flow: nowrap;
			width: 100%;
		}

		li {
			position: relative;

			&,
			&:first-child,
			&.js-active,
			+ li {
				border: 1px solid @c-primary-1;
				border-bottom: 0;
			}

			&.js-active {
				overflow: visible;

				&:before {
					background: @c-white;
					bottom: -1px;
					content: '';
					display: block;
					height: 1px;
					left: 0;
					position: absolute;
					width: 100%;
				}
			}
		}
	}
}


// ***********************************************************************
// MEDIA QUERY: smartphone portrait
// ***********************************************************************
@media screen and (max-width: 479px) {
	.cc-window {
		&.cc-floating {
			width: 90vw;
		}
	}

	.dp--cookie-check {
		flex-wrap: wrap;
		justify-content: center;
	}

	.cc-button-info {
		margin: 5px 0 5px;
	}
}
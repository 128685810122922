.cc-bottom {
  bottom: 0;
}
.cc-right {
  right: 0;
}
.cc-left {
  left: 0;
}
.cc-top {
  top: 0;
}
.cc-window {
  transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  -webkit-transform: translate(-50%, -50%);
  backface-visibility: hidden;
  box-shadow: 0 0 60px 20px rgba(0, 0, 0, 0.15);
  display: block;
  font-size: 14px;
  left: 50%;
  line-height: inherit;
  overflow: auto;
  position: fixed;
  top: 50%;
  z-index: 99999;
}
.cc-window.cc-bottom {
  bottom: auto;
}
.cc-window.cc-floating {
  max-width: 820px;
  width: 100%;
}
.cc-window.cc-floating .cc-compliance {
  flex: 0;
  justify-content: space-between;
  padding: 0 20px 20px;
}
.cc-window.cc-floating .cc-compliance .cc-btn {
  background-color: #0080bc;
  color: #ffffff;
  border-radius: 8px;
  box-sizing: border-box;
  color: #FFF;
  font-weight: 500;
  padding: 6px 25px;
  user-select: none;
  text-align: center;
  word-break: keep-all;
  flex: 0 1 auto;
  width: 37%;
}
.cc-window.cc-floating .cc-compliance .cc-btn:hover,
.cc-window.cc-floating .cc-compliance .cc-btn:focus {
  background-color: #4da6d0;
  color: #ffffff;
}
.cc-window.cc-floating .cc-compliance .cc-btn.inactive,
.cc-window.cc-floating .cc-compliance .cc-btn[disabled] {
  background-color: #ddd7d7 !important;
  color: #424242 !important;
  cursor: default;
  pointer-events: none;
}
.cc-window.cc-floating .cc-compliance .cc-btn.inactive:before,
.cc-window.cc-floating .cc-compliance .cc-btn[disabled]:before {
  display: none;
}
.cc-window.cc-floating .cc-compliance .cc-btn:hover,
.cc-window.cc-floating .cc-compliance .cc-btn:focus {
  text-decoration: none;
}
.cc-window.cc-color-override-1828008485 {
  background-color: #ffffff !important;
}
.cc-window.cc-color-override-1828008485.cc-window {
  color: #000000;
}
body.dp--cookie-consent {
  overflow: hidden;
  padding-top: 0;
  position: relative;
}
body.dp--cookie-consent::before {
  display: block;
}
body::before {
  background: rgba(0, 0, 0, 0.8);
  content: '';
  display: none;
  height: 100%;
  opacity: 1;
  position: fixed;
  width: 100%;
  z-index: 99998;
}
.dp--cookie-check {
  border-color: #0080bc;
  border-radius: 0;
  margin: 20px 0 0;
  padding: 5px 7px;
}
.cc-checkboxes {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
}
.cc-checkboxes label + label {
  margin-left: 20px;
}
.cc-message {
  flex: 1;
}
.cc-message-inner span {
  color: #000000;
}
.cc-headline {
  background-color: #f3f9ff;
  margin: -20px -20px 10px -20px;
  padding: 20px 20px 20px 100px;
  position: relative;
}
.cc-headline:before {
  background: url('../Image/vhw_logo_blue_without_text.svg') no-repeat center;
  bottom: 10px;
  content: '';
  display: block;
  left: 20px;
  position: absolute;
  top: 10px;
  width: 67px;
}
.cc-headline h2 {
  color: #0080bc;
  font-size: 24px;
  font-weight: 500;
  line-height: 28px;
  margin: 0;
  text-transform: none;
}
.cc-floating.cc-theme-edgeless {
  max-height: 80vh;
}
.cc-floating.cc-theme-edgeless .cc-message {
  padding: 20px;
}
.cc-color-override-1828008485 .cc-highlight .cc-btn {
  transition: background-color 0.4s;
}
.cc-color-override-1828008485 .cc-highlight .cc-btn.cc-allow:first-child {
  background-color: #0080bc;
  color: #ffffff;
}
.cc-color-override-1828008485 .cc-highlight .cc-btn.cc-allow:first-child:hover,
.cc-color-override-1828008485 .cc-highlight .cc-btn.cc-allow:first-child:focus {
  background-color: #4da6d0;
  color: #ffffff;
}
.cc-color-override-1828008485 .cc-highlight .cc-btn.cc-dismiss {
  background-color: #0080bc;
  color: #ffffff;
}
.cc-color-override-1828008485 .cc-highlight .cc-btn.cc-dismiss:hover,
.cc-color-override-1828008485 .cc-highlight .cc-btn.cc-dismiss:focus {
  background-color: #4da6d0;
  color: #ffffff;
}
.dp--cookie-consent .dp--cookie-check .cc-button-info.button {
  background-color: #c0c0c0;
  color: #000000;
}
.dp--cookie-consent .dp--cookie-check .cc-button-info.button:hover,
.dp--cookie-consent .dp--cookie-check .cc-button-info.button:focus {
  background-color: #d3d3d3;
  color: #000000;
}
.cc-button-info.js-active::before {
  transform: rotate(180deg);
  -moz-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  -webkit-transform: rotate(180deg);
}
.cc-information {
  display: none;
}
.cc-information.js-active {
  display: block;
}
.cc-tabs-first-level ul,
.cc-tabs-second-level ul {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  list-style: none;
}
.cc-tabs-first-level li,
.cc-tabs-second-level li {
  background-color: #f7f7f7;
}
.cc-tabs-first-level li.js-active,
.cc-tabs-second-level li.js-active {
  background-color: #ffffff;
}
.cc-tabs-first-level {
  margin-top: 15px;
}
.cc-tabs-first-level li {
  border: 1px solid #0080bc;
  border-bottom: none;
  box-sizing: border-box;
  overflow: auto;
}
.cc-tabs-first-level li a {
  display: block;
  padding: 10px 20px;
}
.cc-tabs-first-level li + li {
  border-left: none;
}
.cc-tabs-first-level li.js-active {
  overflow: visible;
  position: relative;
}
.cc-tabs-first-level li.js-active::before {
  background: #ffffff;
  bottom: -1px;
  content: '';
  height: 1px;
  left: 0;
  position: absolute;
  width: 100%;
}
.cc-tabs-second-level {
  margin: -10px 15px 0 -10px;
}
.cc-tabs-second-level ul {
  border-right: 1px solid #0080bc;
  display: block;
  height: 100%;
  width: 152px;
}
.cc-tabs-second-level li {
  border: 1px solid #0080bc;
  border-left: none;
  box-sizing: border-box;
  margin: 0 -1px 0 0;
  overflow: auto;
}
.cc-tabs-second-level li a {
  display: block;
  padding: 5px 10px;
  white-space: nowrap;
}
.cc-tabs-second-level li.js-active {
  border-right: none;
}
.cc-tabs-second-level li + li {
  border-top: none;
}
.cc-tabs-second-level li:first-child {
  border-top: none;
}
.cc-information-contents {
  border: 1px solid #0080bc;
  max-height: 200px;
  overflow: auto;
  padding: 10px;
}
.cc-information-contents > div {
  display: none;
}
.cc-information-contents > div.js-active {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
}
.cc-inner-contents > div {
  display: none;
}
.cc-inner-contents > div.js-active {
  display: block;
}
.cc-inner-text,
.cc-inner-table {
  width: 100%;
}
.cc-inner-table {
  margin-bottom: 20px;
  margin-top: 20px;
}
.cc-inner-table thead {
  background-color: #0080bc;
  color: #ffffff;
}
.cc-inner-table th,
.cc-inner-table td {
  padding: 5px;
  word-wrap: break-word;
}
.cc-inner-table th:nth-child(4),
.cc-inner-table td:nth-child(4) {
  width: 60px;
}
.cc-inner-table th:nth-child(5),
.cc-inner-table td:nth-child(5) {
  width: 45px;
}
.cc-inner-table th {
  text-align: left;
}
.cc-inner-table td {
  vertical-align: top;
}
.cc-inner-table tbody tr:nth-child(even) td {
  background-color: #f7f7f7;
}
.cc-inner-text p {
  margin-bottom: 10px;
}
.cc-inner-text:not(:only-child) p:last-child {
  margin-bottom: 0;
}
@media screen and (max-width: 1024px) {
  .cc-window.cc-floating {
    max-width: 90vw;
  }
  .cc-window.cc-right {
    left: 50%;
    right: auto;
  }
}
@media screen and (max-width: 767px) {
  .cc-window.cc-floating .cc-compliance {
    display: block;
  }
  .cc-window.cc-floating .cc-compliance .cc-btn {
    width: 100%;
  }
  .cc-window.cc-floating .cc-compliance .cc-btn + .cc-btn {
    margin-top: 10px;
  }
  .cc-information-contents > div.js-active {
    display: block;
  }
  .cc-tabs-second-level {
    margin: 0;
  }
  .cc-tabs-second-level ul {
    border-bottom: 1px solid #0080bc;
    border-right: 0;
    display: flex;
    margin: 0 0 10px;
    flex-flow: nowrap;
    width: 100%;
  }
  .cc-tabs-second-level li {
    position: relative;
  }
  .cc-tabs-second-level li,
  .cc-tabs-second-level li:first-child,
  .cc-tabs-second-level li.js-active,
  .cc-tabs-second-level li + li {
    border: 1px solid #0080bc;
    border-bottom: 0;
  }
  .cc-tabs-second-level li.js-active {
    overflow: visible;
  }
  .cc-tabs-second-level li.js-active:before {
    background: #ffffff;
    bottom: -1px;
    content: '';
    display: block;
    height: 1px;
    left: 0;
    position: absolute;
    width: 100%;
  }
}
@media screen and (max-width: 479px) {
  .cc-window.cc-floating {
    width: 90vw;
  }
  .dp--cookie-check {
    flex-wrap: wrap;
    justify-content: center;
  }
  .cc-button-info {
    margin: 5px 0 5px;
  }
}
